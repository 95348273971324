import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo, useRef } from 'react'
export interface PopupFrame {
  className?: string
  open: boolean
  children: JSX.Element[]
  onClose: () => void
  type?: string
  closeText?: string
}

export function PopupFrame({
  className,
  open = false,
  children,
  onClose,
  type,
  closeText = 'Đóng',
}: PopupFrame) {
  const titleChild = useMemo(
    () => children.filter(f => f.type.displayName === 'PopupTitle'),
    [children]
  )
  const bodyChild = useMemo(
    () =>
      children.filter(f => f.type.displayName === ('PopupBody' || 'FormGroup')),
    [children]
  )
  const closeRef = useRef(null)
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={closeRef}
        as='div'
        className={`fixed left-[0] top-[0] z-[999] w-full`}
        onClose={onClose}
      >
        <div className='text-center'>
          <div
            className='fixed inset-0 bg-dark-900 opacity-50'
            aria-hidden='true'
            onClick={onClose}
          />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={`${className} z-40 inline-block transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all`}
            >
              {type !== 'choose_location' && type !== 'voucher' && (
                <div className='absolute right-0 top-0 cursor-pointer p-3'>
                  <div onClick={onClose} ref={closeRef}>
                    <IconClose />
                  </div>
                </div>
              )}

              <Dialog.Title
                as='div'
                className={
                  type === 'choose_location'
                    ? 'bg-blue-400 text-white-400'
                    : '' && 'relative'
                }
              >
                {titleChild}
                {type === 'choose_location' && (
                  <div
                    onClick={onClose}
                    ref={closeRef}
                    className='absolute right-4 top-3 flex cursor-pointer items-center gap-1'
                  >
                    <IconClose />
                    {closeText}
                  </div>
                )}
              </Dialog.Title>
              {bodyChild}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PopupFrame

interface IconClose {
  className?: string
}

export const IconClose = ({ className }: IconClose) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z'
        fill='white'
      />
    </svg>
  )
}

export const IconCloseB = ({ className }: IconClose) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
    >
      <path
        d='M14.7 1.8C14.3 1.4 13.7 1.4 13.3 1.8L8 7.1L2.7 1.8C2.3 1.4 1.7 1.4 1.3 1.8C0.9 2.2 0.9 2.8 1.3 3.2L6.6 8.5L1.3 13.8C0.9 14.2 0.9 14.8 1.3 15.2C1.5 15.4 1.7 15.5 2 15.5C2.3 15.5 2.5 15.4 2.7 15.2L8 9.9L13.3 15.2C13.5 15.4 13.8 15.5 14 15.5C14.2 15.5 14.5 15.4 14.7 15.2C15.1 14.8 15.1 14.2 14.7 13.8L9.4 8.5L14.7 3.2C15.1 2.8 15.1 2.2 14.7 1.8Z'
        fill='#303538'
      />
    </svg>
  )
}
